import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { ContentRenderer } from "../components/ContentRenderer";
import { SEO } from "../components/SEO";
import { Section } from "../components/Section";
import styled from "styled-components";
import { Link } from "../components/Link";
import { BgImage } from "gbimage-bridge";
import { SocialMediaLinksFooter } from "../components";
import qs from "query-string";
import { render } from "datocms-structured-text-to-plain-text";

const RelatedPosts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const RelatedPost = styled(Link)`
  background: white;
  h5 {
    margin: 0 10px 10px 10px;
  }
  border: 1px solid ${(p) => p.theme.borderColor};
  transition: box-shadow 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  color: ${(p) => p.theme.bodyFontColor};
  &:hover {
    text-decoration: none;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
  }
`;

const Category = styled.div`
  margin: 15px 10px 10px 10px;
  text-transform: uppercase;
  font-size: 12px;
  color: ${(p) => p.theme.mutedColor};
`;

const PageTemplate = (props) => {
  const { datoCmsPage } = props.data;

  return (
    <>
      <SEO
        title={`${datoCmsPage.seo.title} | Rubric`}
        description={datoCmsPage.seo.description}
        image={datoCmsPage.seo.image?.url}
        url={props.pageContext.url}
        type="website"
        breadcrumbTrails={[
          datoCmsPage.breadcrumbs.map((breadcrumb, i) => ({
            name: breadcrumb.name,
            item:
              i !== datoCmsPage.breadcrumbs.length - 1 &&
              !!breadcrumb.page?.slug
                ? `https://rubric.com/en-us/${breadcrumb.page.slug}`
                : null,
          })),
        ]}
      />
      <Layout>
        <ContentRenderer content={datoCmsPage.content} page />
        {!!datoCmsPage.showSocialMediaLinksSection && (
          <SocialMediaLinksFooter />
        )}
        {!!datoCmsPage.pageFooter && (
          <Section
            backgroundImage={
              datoCmsPage.pageFooter.backgroundImage.gatsbyImageData
            }
            backgroundOverlay={datoCmsPage.pageFooter.backgroundOverlay}
          >
            <ContentRenderer content={datoCmsPage.pageFooter.content} />
            <RelatedPosts>
              {(datoCmsPage.relatedPosts || []).map((post) => (
                <RelatedPost key={post.id} to={post.slug}>
                  <BgImage
                    style={{ height: 100 }}
                    image={post.seo.image.gatsbyImageData}
                  />
                  <Category>{post.category.title}</Category>
                  <h5>{post.title}</h5>
                </RelatedPost>
              ))}
            </RelatedPosts>
          </Section>
        )}
      </Layout>
    </>
  );
};

export const query = graphql`
  query PageQuery($id: String) {
    datoCmsPage(id: { eq: $id }) {
      title
      slug
      showSocialMediaLinksSection
      seo {
        title
        description
        image {
          url
        }
      }
      breadcrumbs {
        name
        page {
          slug
        }
      }
      pageFooter {
        ...Link_Section
      }
      relatedPosts {
        id: originalId
        slug
        title
        seo {
          description
          image {
            gatsbyImageData
          }
        }
        category {
          title
        }
      }
      content {
        links {
          __typename
          ... on DatoCmsSection {
            ...Link_Section
          }
        }
        blocks {
          __typename
          ... on DatoCmsVerticalRedLine {
            id: originalId
          }
          ... on DatoCmsAlignedText {
            ...Block_AlignedText
          }
          ... on DatoCmsMiniHeading {
            ...Block_MiniHeading
          }
          ... on DatoCmsHighlightedHeading {
            ...Block_HighlightedHeading
          }
          ... on DatoCmsSubPagesList {
            ...Block_SubPagesList
          }
          ... on DatoCmsYoutubeEmbed {
            id: originalId
            youtubeVideo
          }
        }
        value
      }
    }
  }
`;

export default PageTemplate;
